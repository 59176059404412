import './aoa.css';

const Aoa = () => {
  return (
    <div id="mainT">
      Página 404
    </div>
  );
}

export default Aoa;