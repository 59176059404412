import React from "react";

const Fecha = (props) => {
    function format (){
        const months = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
        let form = props.date.split('-');
        let m = '';
        let y = ''
        form.map((elem,index)=>{index === 0 ? y = elem : m = months[parseInt(elem)-1]; return null})
        return (<span className="aboutme_card_date">{`${m} / ${y}`}</span>);
    }
    const data = format();
    return (
        <>
            {data}
        </>
    )
}

export default Fecha;