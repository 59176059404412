import React from 'react';
import './footer.css';

const Footer = (props) => {
    return (
        <footer>
            <ul>
                <li><a href="https://www.linkedin.com/in/david-muñoz-trollund-3bb111a" target="_blank" rel="noreferrer"><img alt='Icono Linkedin' src="./assets/icons/linkedin.png" /><p>Ver perfil en Linkedin</p></a></li>
                <li><span className="material-symbols-outlined">call</span><p>(+56) 9 4211 0098</p></li>
                <li><span className="material-symbols-outlined">mail</span><p>david.trollund@gmail.com</p></li>
                <li><span className="material-symbols-outlined">location_on</span> <p>La Florida, Santiago</p></li>
            </ul>
        </footer>
    )
}

export default (Footer);