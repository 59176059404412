import React from 'react';
import {fetchData} from '../../fetch/fetchData.js';
import './menu.css';
import { Link } from 'react-router-dom';

const apiData = fetchData("http://davmund.cl/mantenedor/servicios/listaCategorias.php");

const Menu = (props) => {
    const data = apiData.read();
    return(
        <div id="menu_options" className="header_menu">
            <ul id="menu_options" className="header_menu_options">
                <Link className='Link' to="/Home"><li key="Home" data-bot="option" data-catid="0" id="_Home">Home</li></Link>
                <Link className='Link' to="/About"><li key="about_me" data-bot="option" data-catid="1" id="_Sobre mi">Sobre mi</li></Link>
                <Link className='Link' to="/Contacto"><li key="contacto" data-bot="option" data-catid="2" id="_Contacto">Contacto</li></Link>
                {/* {data?.listacategorias.map(
                    (cat,index)=>(
                        <Link key={`${index}`} className='Link' to="/Home">
                            <li key={cat.id_cat} data-bot="option" data-catid={cat.id_cat} id={`_${cat.nombre_categoria}`}>
                                {cat.nombre_categoria}
                            </li>
                        </Link>
                    ))
                } */}
            </ul>
        </div>
    )
}

export default (Menu);