import React, { Suspense } from 'react';
import './seccion.css';
import {fetchData} from '../../fetch/fetchData.js';
import Miniatura from '../miniatura/Miniatura';

const apiData = fetchData("http://davmund.cl/mantenedor/servicios/listaProyectos.php");

const Seccion = (props, ref) => {
    const data = apiData.read();
    return (
        <div className="main">
            <div id={props.categoria}>
                <div id="title">
                    <h2>{props.categoria}</h2>
                </div>
                <div id={props.catId} className="demo">
                    <Suspense key='s2'  fallback={<div>Cargando...</div>}>
                        {data?.listaproyectos.map(//mapeando la lista de proyectos
                            (proy)=>{
                                if(proy.category === props.catId){
                                const img = proy.images.split(',');
                                return <Miniatura 
                                    key={proy.id} 
                                    proy={proy.id} 
                                    categoria={props.categoria} 
                                    titulo={proy.name} 
                                    cliente={proy.cliente}
                                    imagen={img[0]}
                                    action={props.action}>
                                </Miniatura>
                                } return null;
                            }
                        )}
                    </Suspense>
                </div>
            </div>
        </div>
    )
}

export default Seccion;