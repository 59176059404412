import './contacto.css';
import React, { useEffect } from 'react';
import Formulario from '../../componentes/formulario/Formulario';

const Contacto = () => {
    function scrollingWindows (){
        return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
      }
      useEffect(()=>{
        scrollingWindows()
      })
    return(
        <div className='central'>
            <div className="contactoContenedor">
                <div className="contactoContext">
                    <h2>
                        ¡Estoy aquí para ayudarte y me encantaría saber de ti!
                    </h2>
                    <p>
                        Si tienes alguna pregunta o deseas saber más sobre mis servicios, no dudes en contactarme por whatsapp o enviando un mensaje.
                    </p>
                </div>
                <div className="constactoForm">
                    <Formulario />
                </div>
            </div>
        </div>
    )
}

export default Contacto;