import React from 'react';
import Proyecto from '../proyecto/Proyecto';
import './modal.css';

const Modal = (estado,cambiarEstado) => {
    return(
        <>
            {estado.estado.on &&
            
                <div id="modal" className='modal'>
                    <div className='contenedor' id="modal_contenedor">
                        <div onClick={()=>{estado.cambiarEstado({type:null,on:false})}} className='modal_close material-symbols-outlined'>close</div>
                        {estado.estado.type === 'proy' &&
                            <Proyecto key={`proy_${estado.estado.id}`} id={estado.estado.id} cat={estado.estado.cat}></Proyecto>
                        } 
                        <div className='contenedor_volver' onClick={()=>{estado.cambiarEstado({type:null,on:false})}}>Volver</div>
                    </div>
                </div>
            }
        </>
    )
}

export default Modal;