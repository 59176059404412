import React, { useEffect } from 'react';
import Menu from '../menu/Menu';
import './header.css';
import { Link } from 'react-router-dom';

const Header = (props) => {

  useEffect(()=>{
    const header = document.getElementById('header');;
    window.onscroll = (e) => {
      const logo = document.getElementById('logo');
      if(window.scrollY > 0){
        logo.classList.add('logoS'); logo.classList.remove('logoL')
      }else{
        logo.classList.add('logoL'); logo.classList.remove('logoS')
      }
    };

    // escuchando el alto de la ventana
    function checkdimension (){
      header.style.top = 'auto';
      header.style.bottom = '0';
      window.addEventListener('resize',()=>{
          console.log('window.innerHeight');
          /* header.style.top = window.innerHeight - header.clientHeight; */
          header.style.bottom = '0';
          header.style.top = 'auto';
      },true)
    }

    const h = window.innerWidth < 701 ? checkdimension():null;
  })
  
  return(
    <header id='header' className='shadow'>
      
        <div id="logo" className="header_brand logo logoL">
          <Link to={'/Home'}><div id="logoA">
              <img alt="logo davmund" src='./assets/DSR_logo.png' />
          </div></Link>
        </div>
        <Menu />
    </header>
)
}

export default (Header);